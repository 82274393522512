// .navbar-light {

//     .nav-link {
//         font-size: 1.5rem;
//         color: rgba(0, 0, 0, 1) !important;

//         &.btn-gradient {
//             color: #fff !important;

//             &:hover {
//                 color: #fff !important;
//             }
//         }

//         &:hover {
//             color: $clr-theme-light !important;
//         }
//     }
// }

// @media (max-width: $media_xs) {
//     .navbar-light {
//         .navbar-brand {
//             max-width: 70%;
//             display: inline-block;

//             .navbar-brand-image {
//                 max-width: 100%;
//             }
//         }
//     }
// }

// @media (max-width: $media_lg) {
//     .mobile-nav {
//         flex-basis: 100%;
//         flex-grow: 1;
//         align-items: center;

//         .list-inline-item {
//             a {
//                 display: block;
//                 padding: 0.5rem 2rem;
//                 text-align: center;

//                 &:hover {
//                     text-decoration: none;
//                     color: $clr-theme-light;
//                 }
//             }
//         }
//     }
// }


/* HEADER STYLES */
$margin_y: 25px;

#homepage {

    #header {
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 90;
    }
    .btn-menu {
        .menu-icon {
            border: 1px solid #eee;
            padding: 15px 15.25px 13px 16px;
            border-radius: 50%;
        }

        span {
            background-color: #eee;
        }

        .menu-text {
            color: #eee;
        }

    }

    .contact-info {

        span {
            color: #eee;
        }
    }


}

#header {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#d9d9d9+0,ffffff+100 */
    background: transparent;
    // background: #d9d9d9;
    /* Old browsers */
    background: -moz-linear-gradient(top, #d9d9d9 0%, #ffffff 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #d9d9d9 0%, #ffffff 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #d9d9d9 0%, #ffffff 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d9d9d9', endColorstr='#ffffff', GradientType=0);
    /* IE6-9 */
}

.header-container {
    width: 100%;
    padding: 0 30px;
    color: #666;
    height: auto;
    .company-brand{
        text-align: right;
    }
    /* Header logo */
    img {
        margin: 10px 0;
        margin-right: 60px;
    }

    .social-container {
        position: relative;
        margin: $margin_y 0;

        .social-popup,
        .social-popup-trigger {
            display: none;
        }

        .social-link {
            color: #ffffff;
            width: 60px;

            i {
                vertical-align: middle;
            }
        }
    }
}

/* HEADER CONTACT */
.contact-info {
    margin: $margin_y 0;
    text-align: right;
    margin-top: 40px;

    span {
        display: inline-block;
        margin-left: -4px;
        line-height: 32px;
        // border-right: 1px solid $border_light;
        //color: #eee;
        padding: 0 30px;
        font-size: 1rem;

        &.line-divide {
            border-left: 1px solid #cccccc;
        }

        a {
            text-transform: uppercase;


            &:hover {
                text-decoration: none;
            }
        }

        i {
            color: #ffffff;
            margin-right: 15px;

            &.fa-phone {
                font-size: 1.6rem;
            }

            &.fa-skype {
                font-size: 1.5rem;
            }

            &.fa-envelope {
                font-size: 1.4rem;
            }
        }

        &:last-of-type {
            border: 0;
            padding-right: 0;
        }

        &.text-muted {
            display: inline;
            margin-left: 1px;
        }
    }

    label {
        display: inline;
        margin: 0;
    }
}

/* BUTTON HAMBURGER */
.btn-menu {
    margin-left: auto;
    display: inline-block;
    padding: $margin_y+10;
    color: #eee;

    &:hover {
        text-decoration: none;
    }

    .menu-icon {
        border: 1px solid #c1c1c1;
        padding: 15px 15.25px 13px 16px;
        border-radius: 50%;


    }

    .menu-text {
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 32px;
        position: relative;
        top: -20px;
        left: 5%;
    }

    span {
        display: block;
        background-color: #666;
        width: 20px;
        height: 3px;
        margin-bottom: 4px;
    }
}

/* MEDIA QUERIES */
@media (max-width: 1700px) {
    .contact-info {
        span {
            line-height: 30px;
            padding: 0 8px;

            i {
                margin-right: 5px;
            }
        }
    }
}

@media (max-width: 1600px) {
    .header-container {
        .social-container {
            .social-link {
                width: 40px;
                line-height: 30px;
            }
        }
    }

    .contact-info {
        span {
            line-height: 30px;
            padding: 0 8px;

            i {
                margin-right: 5px;
            }
        }
    }
}

@media (max-width: 1300px) {
    .contact-info {

        //text-align: left;
        span {
            label {
                display: none;
            }
        }
    }
}

@media(max-width: 1040px) {
    .contact-info {
        span {
            padding: 0 4px;
            border: 0 !important;
            font-size: 1.1rem;
        }
    }
}

@media (max-width: $media_xl) {
    .contact-info {
        .text-muted {
            display: none;
        }
    }
}
@media (max-width: $media_lg){
    .header-container {
        .company-brand {
            text-align: center !important;
        }
    }
    .btn-menu {
        .menu-text {
            display:none !important;
        }
    }
    .mobile-lang{
        font-size: 1rem;
        height: 38px;
    }
    
    .mobile-book{
        position: relative;
        top: 45px;
        font-size: 1.2rem;
        background-color: color(btn-clr-large);
        i{
            color: color(white-clr);
        }
    }
}
@media (max-width: $media_md){
    .btn-menu{
        padding-right: 15px;
        padding-left: 15px;
    }
}
@media (max-width: $media_sm) {
    .header-container {
        padding: $margin_y;
        padding-top: 0;
    }
}

@media (max-width: $media_sm) {
    .btn-menu {
        padding-left: 0;
    }
}

@media (max-width: $media_xs){
    .header-container{
        img{
            padding-top:10px;
        }
    }
    .btn-menu {
        padding-right: 0;
        padding-bottom: 0;
        .menu-icon {
            padding: 11px 11.25px 9px 12px;
        }
        span {
            width: 15px;
            height: 2px;
        }
    }
    
  }
  