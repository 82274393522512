.white {
    background: color(white-clr);
    color: color(text-dark);
}

.special-offer-slider-container {
    .navi {
        &.navi-prev {
            left: -60px;
        }

        &.navi-next {
            right: -60px;
        }
    }

    .special-offer-slider-item {
        section {
            padding: 5vh 4vh;


            header {


                h4 {
                    font-size: 1.7rem;
                    font-family: font(montserrat);
                    color: color(text-darker);
                    text-transform: uppercase;
                    overflow-wrap: anywhere;
                    font-weight: 300;
                }
            }


            p {
                font-size: 1rem;
                font-family: font(montserrat);
                color: color(text-light);
                overflow-wrap: anywhere;
            }

            .special-offer-btn {
                font-weight: 700;
                font-family: font(montserrat);
                color: color(text-dark);
                text-transform: uppercase;
                font-size: 1rem;
                position: absolute;
                bottom: 5vh;

                i {
                    color: #daaf5c;
                    font-size: 1.3rem;
                    padding-left: 10px;
                }
            }
        }
    }
}


@media (min-width: 1575px) {
    .special-offer-slider-container {
        .navi {
            &.navi-prev {
                left: -220px;
            }

            &.navi-next {
                right: -220px;
            }
        }
    }
}

@media (max-width: $media_md) {
    .special-offer-slider-container {
        .special-offer-text {
            padding-top: 10px;
            padding-bottom: 10px
        }
    }
}