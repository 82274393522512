.group-view-section {
    padding-top: 0 !important;

}

.intro-group-section {
    padding-top: 5vh;
}

.room-item {
    background-color: color(color-them-light);
    -webkit-box-shadow: 1px 1px 50px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 1px 1px 50px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 1px 1px 50px 2px rgba(0, 0, 0, 0.3);

    font-size: 12px;
    font-weight: 300;

    .room-item-title {
        font-weight: 400;
        font-family: font(montserrat);
        font-size: 1.8rem;
    }

    .room-item-subtitle {
        font-family: font(great-vibes);
    }

    .room-item-text-overlay {
        background: rgba(color(text-dark), 0.2);
        padding-top: 5px;
        padding-bottom: 10%;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: auto;
    }

    .img-overlay {
        position: relative;
        left: 5%;
        max-width: 90%;
        z-index: 2;
    }

    hr.divider {
        border-top: 4px solid color(subtitle-color);
        width: 20%;
    }


    .room-item-header {
        background-color: transparent;
        border-bottom: none;
    }

    .room-item-icon {
        background-position: -2px -1px !important;
        background-repeat: no-repeat !important;
        width: 44px;
        height: 39px;
        transform: scale(0.6);
        padding-left: 2px;
    }

    .room-item-text {
        line-height: 2;
        p {
            font-size: 1rem;
        }
    }

    .room-item-footer {
        border-top: none;
        background: transparent;
    }

    &:nth-child(even) {
        @include gradient-two-clr-linear(#ad824b, #daaf5c);
        color: #fff;

        .room-item-header {
            background: transparent;
            color: #f1e8dc;
        }

        .room-item-icon {
            background-position: -95px 0px !important;
            background-repeat: no-repeat !important;
            width: 44px;
            height: 39px;
        }

        .btn-large {
            border: #ecdfcb 2px solid;
        }

        .btn-small {
            background-color: transparent;
            border-color: #d4b787;
            color: color(white-clr);
        }
    }
}