.sitemap-text {
    text-align: left;
    color:color(text-dark);

    p{
        font-size: 1rem;
    }
    ul {
        font-size: 1rem;
        li {
            a {
                &:hover{
                    color: color(subtitle-color);
                }
            }
        }
    }
}