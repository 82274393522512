$dark_bg: #36434d;
/* ACTION COMPONENT STYLES */
.action-component {
    text-align: center;
    padding: 100px 0;
    background-color: $dark_bg;
    color: #ffffff;
    .card {
        .card-header {
            font-family: $font_alt;
            font-size: 4.8rem;
            font-weight: 500;
            margin: 0 auto;
            max-width: 900px;
        }
        .card-text {
            font-size: 1.6rem;
            margin: 0 auto;
            max-width: 900px;
            line-height: 1.4;
        }
        a {
            margin: 6px;
        }
        .card-action {
            margin-top: 40px;
        }
    }
    
    &.light-theme {
        color: $text_dark;
        background-color: transparent;
        .dmn-button {
            color: #ffffff;
        }
    }

    &.intro-text {
        padding-bottom: 0 !important;
    }
}
/* MEDIA QUERIES */
@media (max-width: $media_md) {
    .action-component {
        padding: 60px 0;
        .card {
            .card-header {
                font-size: 4rem;
            }
        }
    }
}