.smallheader-wrapper {
/*    background: url('../images/background/text-page-bg.jpg') no-repeat;*/
    background-position: center;
    background-size: cover;
    top: 0;
    position: relative;
    margin-bottom: 5vh;

    .intro-text {
        padding-top: 25vh;
        padding-bottom: 5vh;
        text-align: center;
        color: #fff;
    }
}