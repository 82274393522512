.terms-and-conditions-text {
    padding-bottom: 7vh;
    font-family: $font_Open_Sans;
    color: $text-dark;
    text-align: left;

    p strong {
        font-weight: 700;
        display: inline-block;
        margin-top: 1%;
        margin-bottom: 1%;
    }

    ul {
        li {
            font-size: 1rem;
            font-weight: 300;
            padding-left: 2%;
        }
    }
}

@media (max-width: $media_lg) {
    .terms-and-conditions-text {
        p strong {
            margin-top: 3%;
            margin-bottom: 3%;
        }


    }
}

@media (max-width: $media_sm) {
    .terms-and-conditions-text {
        p strong {
            margin-top: 5%;
            margin-bottom: 5%;
        }

        ul {
            li {
                padding-left: 5%;
            }

        }
    }
}