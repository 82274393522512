//@import "~bootstrap/scss/bootstrap";

@import "elements/mixins";
@import "elements/global";

@import "elements/buttons";

@import "elements/header";
@import "elements/footer";
@import "elements/cookie";

@import "elements/main_navigation";
@import "elements/header_slider";

@import "elements/smallheader";
@import "elements/terms_and_conditions_component";
@import "elements/class_section";
@import "elements/featured_block_component";
@import "elements/pdf_download";
@import "elements/rooms_component";
@import "elements/room_detail_component";
@import "elements/text_page_component";
@import "elements/sitemap_component";
@import "elements/comparison_overview";
@import "elements/gmap_component";
@import "elements/dropdown_form_component";

@import "elements/action_component";
//@import "elements/references_component";
// @import "elements/services_component";
@import "elements/slider_component";
@import "elements/rooms_slider_component";
@import "elements/special_offer_slider_component";
@import "elements/home_video_component";
@import "elements/text_component";
@import "elements/form";
@import "elements/scrollbar";
@import "elements/grid_component";
@import "elements/flash_messages";