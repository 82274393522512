.watermark-full {
    background: url('../../images/watermark-full.png') no-repeat;
    background-position: center;
    // background-size: cover;
}
.room-detail-section{
    padding-top: 5vh;
}  

.room-detail {
    color: color(text-dark);
    padding-bottom: 8vh;
    padding-top: 5vh;
    .room-detail-text{
        
        p {
            font-size: 1rem;
        }
        ul {
            font-size: 1rem;
        }
    
        ul li::before {
            content: "\2022";
            /* Add content: \2022 is the CSS Code/unicode for a bullet */
            color: #ad824b;
            /* Change the color */
            font-weight: bold;
            /* If you want it to be bold */
            display: inline-block;
            /* Needed to add space between the bullet and the text */
            width: 1rem;
            /* Also needed for space (tweak if needed) */
    
            /* Also needed for space (tweak if needed) */
        }
    }
    
    .share-widget {
        position: absolute;
        color: #daaf5c;
        right: 0;
        top: 0;
    }

    hr.detail-divider {
        border-top: 3px solid #daaf5c;
        width: 10%;
    }

    .room-item-icons-detail {
        padding-bottom: 4vh;

        .room-item-icon-detail {
            background-position: -48px -1px;
            background-repeat: no-repeat;
            width: 44px;
            height: 41px;
            transform: scale(0.6);
            padding-left: 5px;
        }
        .room-item-icon-detail-name{
            font-size: 0.672rem;
            // padding-left: 10px;
            text-transform: uppercase;
            
        }
    }
}