/* COMPARISON OVERVIEW(TABLE) STYLES */
$border_color: #cccccc;
$box_shadow:  0 0 18px rgba(0,0,0,0.2);

.comparison-overview {
    padding: 95px 0 175px;
}

.comparison-table {
    flex-direction: row;
    @include d-flex;
    @include justify-content-between;

    .column {
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        .title {
            font-weight: 700;
            font-size: 2rem;
            text-transform: uppercase;
            height: 70px;
            line-height: 70px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
        }
        ul {
            margin: 0;
            li {
                padding: 14px 0;
                border-top: 1px solid $border_color;
                font-size: 1.4rem;
                strong, b, span {
                    font-weight: 600;
                }
                span {
                    display: none;
                }
            }
        }
    }
    .col-feature {
        @include flex-width(0 0 50%);
        max-width: 50%;
        padding: 0;
        margin-right: 8px;
    }
    .col-product {
        position: relative;
        max-width: 100%;
        margin: 0 5px;
        background-color: #ffffff;
        box-shadow: $box_shadow;
        border-color: $secondary;
        padding: 0 14px;
        text-align: center;
        @include flex-width(auto);
        @include transition(box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1));

        &:nth-of-type(2) {
            border-color: $primary;
        }
        &:last-of-type {
            border-color: $terniary;
        }
        i {
            &.fa-check {
                font-size: 1.8rem;
                color: #7a8c99;
            }
            &.fa-times {
                font-size: 1.5rem;
                color: #b8c3cc;
            }
        }
        &:hover {
            box-shadow: 0 0 4px rgba(0,0,0,0.4);
        }
        .dmn-button {
            position: absolute;
            bottom: -70px;
            left: 50%;
            white-space: nowrap;
            @include translate(-50%, 0);
        }
    }
    .tab-content {
        @include d-flex;
        @include flex-width(auto);
    }
}
/* MEDIA QUERIES */
@media (min-width: $media_md) and (max-width: $media_xl) {
    .comparison-table {
        .column {
            .title {
                font-size: 1.9rem;
            }
            ul {
                li {
                    font-size: 1.3rem;
                }
            }
        }
    }
}

@media (min-width: 769px) {
    .comparison-table {
        .column {
            &.col-product {
                ul {
                    li {
                        @include d-flex;
                        @include justify-content-center;
                        @include align-items-center;
                    }
                }
            }
        }
    }

    .nav-tabs {
        display: none;
    }
}

@media (max-width: $media_lg) {
    .comparison-table {
        .col-feature {
            @include flex-width(0 0 40%);
            max-width: 40%;
        }
    }
}

@media (max-width: $media_md) {
    .comparison-overview {
        padding: 60px 0 140px;
    }
    .comparison-table {
        box-shadow: $box_shadow;
        @include flex-column;
        .col-feature {
            display: none;
        }
        .column {
            @include flex-width(0 0 100%);
            ul {
                li {
                    height: auto !important;
                    span {
                        display: block;
                        margin-bottom: 5px;
                    }
                }
            }
        }
        .col-product {
            margin: 0;
            box-shadow: none;
        }
    }
}