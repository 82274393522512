/* SLIDER VARS */
$slider_height_sm: 450px;
$slider_height_md: 550px;
$slider_height_lg: 700px;
$slider_height_xl: 800px;
$slider_height: 900px;

/* SLIDER STYLES */
.header-slider-wrapper {
    overflow: hidden;

    .header-slider-item {
        background-size: cover !important;
        background-position: center;
        background-repeat: no-repeat;
        height: 100vh;

        .container {
            height: 720px;
            display: flex;
            align-items: center;
        }

        img {
            width: 100%;
        }
    }

    .navi {
        color: #ffffff;
    }

    .slick-dots {
        position: absolute;
        bottom: 30px;
    }

    #video-holder {
        .play-btn-bg {
            left: 47%;
        }
    }
}

.header-slider-content {
    font-size: 1.8rem;
    font-weight: 300;
    width: 100%;

    .header-slider-title {
        font-family: $font_alt;
        font-weight: 100;
        font-size: 5.5rem;
        line-height: 1.1;
        margin-bottom: 20px;
        max-width: 100%;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.3);

        strong,
        b,
        em,
        i {
            display: block;
            font-weight: 700;
        }
    }

    p {
        margin-bottom: 30px;
        /*         max-width: 32.5rem; */
        line-height: 1.2;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
    }

    .header-slider-text {
        margin: 0 0 30px;

        p {
            margin: 0;
        }
    }

    &.text-right {
        p {
            margin-left: auto;
        }
    }
}

/* YouTube and Image Overlay */
#video-holder {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 5rem;
    .img-overlay {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;

    }

    .play-btn-bg {
        position: absolute;
        top: 50%;
        margin: -100px 0 0 -26px;
        left: 45%;
        padding: 1%;
        border-radius: 50%;
        @include gradient-two-clr-diagonal--45deg(#ad824b, #daaf5c);

        .video-play-btn {
            width: 150px;
            height: 150px;
            background: transparent;
            border-radius: 50%;

            i {
                font-size: 4rem;
                margin: 0 auto;
                color: color(white-clr);
            }
        }
    }
}



/* SLICK NAVIGATION ARROWS */
.navi {
    position: absolute;
    top: 50%;
    margin-top: -25px;
    z-index: 1;
    cursor: pointer;


    &.navi-prev {
        left: 30px; // left 30px for arrow to be inside slider
        color: color(white-clr) !important;
        background: url('../../images/icons/nav-left.png') no-repeat;
        max-width: 42px;
        max-height: 76px;
        width: 42px;
        height: 76px;
    }

    &.navi-next {
        right: 30px; // right 30px for arrow to be inside slider
        color: color(white-clr) !important;
        background: url('../../images/icons/nav-right.png') no-repeat;
        max-width: 42px;
        max-height: 76px;
        width: 42px;
        height: 76px;
    }

    &.slick-disabled {
        @include opacity(0.2);
        pointer-events: none;
        cursor: default;
    }

    i {
        font-size: 7rem;
        font-weight: 300;
    }
}

/* SLICK NAVIGATION DOTS */
.slick-dots {
    position: absolute;
    bottom: -70px;
    width: 100%;
    padding: 0;
    text-align: center;
    margin: 0;

    li {
        display: inline-block;
        margin: 0 4px;

        button {
            border: 2px solid color(white-clr);
            border-radius: 100%;
            width: 12px;
            height: 12px;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
            padding: 0;
            outline: 0;
        }

        &.slick-active {
            button {
                background: color(white-clr);
            }
        }
    }
}

.slider-buttons {
    a {
        img {
            width: auto !important;
            display: inline-block;
        }

        &:last-child {
            margin-left: 10px;

            @media (max-width: 340px) {
                margin-left: 0;

                img {
                    margin-top: 10px;
                }
            }
        }
    }
}

/* MEDIA QUERIES */
@media (max-width: $media_xxl) {
    .header-slider-wrapper {
        .header-slider-item {
            height: $slider_height_lg;

            .container {
                height: $slider_height_lg;
            }
        }

        .navi {
            &.navi-prev {
                left: -60px;
            }

            &.navi-next {
                right: -60px;
            }
        }
    }
}

@media (max-width: 1250px) {
    .header-slider-wrapper {
        .navi {
            &.navi-pre {
                left: -50px;
            }

            &.navi-next {
                right: -50px;
            }
        }
    }
}

@media (max-width: $media_xl) {
    .header-slider-wrapper {

        .header-slider-item {
            height: $slider_height_lg;

            .container {
                height: $slider_height_lg;
            }
        }
    }

    .navi {
        &.navi-prev {
            left: 25px !important;
        }

        &.navi-next {
            right: 25px !important;
        }

    }
}

@media (max-width: $media_lg) {
    .header-slider-wrapper {
        .header-slider-item {
            height: $slider_height_lg;

            .container {
                height: $slider_height_lg;
            }
        }
    }
}

@media (max-width: $media_md) {
    .header-slider-wrapper {
        .header-slider-item {
            height: $slider_height_md;

            .container {
                height: $slider_height_md;
            }
        }
    }

    .navi {
        display: none !important;
    }
    #video-holder{
        .play-btn-bg{
            left: 47%;
            .video-play-btn{
                width: 75px;
                height: 75px;
                i{
                    font-size: 2.5rem;
                }
            }
        }
    }
}

@media (max-width: $media_sm) {
    .header-slider-wrapper {
        .header-slider-item {
            height: $slider_height_sm;

            .container {
                height: $slider_height_sm;
            }
        }
    }

    .header-slider-content {
        font-size: 1.6rem;

        .header-slider-title {
            font-size: 3.2rem;
        }
    }
}

@media (max-width: $media_xs) {
    .header-slider-content {
        font-size: 1.6rem;

        .header-slider-title {
            font-size: 3rem;
            text-shadow: 1px 1px rgba(0, 0, 0, 0.9);
        }

        .header-slider-text {
            p {
                text-shadow: 1px 1px rgba(0, 0, 0, 0.9);
            }
        }
    }
}

body#mobileapp-page {
    .header-slider-content {
        @media (max-width: $media_sm) {
            position: relative;
            top: 50px;
        }
    }
}