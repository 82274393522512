.rooms-slider-container {
    color: #fff;

    .navi {
        &.navi-prev {
            left: -60px;
        }

        &.navi-next {
            right: -60px;
        }
    }

    .rooms-slider-item {

        max-width: 350px;
        margin: 0 10px;

        &.slick-active {
            opacity: 1 !important;
        }

        &.slick-slide {
            opacity: 0.2;
        }

        img {
            height: 500px;
            max-width: 350px;

        }

        .rooms-slider-text-overlay {
            background: rgba(color(text-dark), 0.2);
            position: absolute;
            bottom: 0;
            max-width: 297px;
            width: 100%;
            height: auto;
        }

        .rooms-slider-img-overlay {
            position: relative;
            margin-left: 30px;
            padding-bottom: 10%;
            padding-top: 5px;
            width: 270px;

            h4 {
                font-family: font(montserrat);
                font-size: 1.85rem;
                color: #fff;
                text-transform: uppercase;
                font-weight: 300;
                &.rooms-slider-title{
                    height: 65px;
                }
            }

            .rooms-slider-subtitle {
                height: 70px;
                p {
                    font-family: font(great-vibes);
                    font-size: 2rem;
                    color: #fff;
                    margin: 0;
                }
            }

            a {
                font-family: font(montserrat);
                font-size: 14px;
                text-transform: uppercase;
                color: #fff;
                padding-top: 10px;
                font-weight: 700;

                i {
                    padding-left: 5px;
                    font-size: 1.2rem;
                    color: #daaf5c;
                }
            }



            hr.slider-divider {
                border-top: 3px solid #daaf5c;
                width: 40%;
            }
        }
    }
}

@media (min-width: 1575px) {
    .rooms-slider-container {
        .navi {
            &.navi-prev {
                left: -220px;
            }

            &.navi-next {
                right: -220px;
            }
        }
    }
}

@media (min-width: $media_lg) and (max-width: $media_xl){
    .rooms-slider-container{
        .rooms-slider-item{
            .rooms-slider-text-overlay{
                .rooms-slider-img-overlay{
                    width: 209px;
                    h4{
                        &.rooms-slider-title{
                            height: 100px;
                        }
                    }
                }
            }
        }
    }
}
@media (in-width: 480px) and (max-width: 520px){
    .rooms-slider-container{
        .rooms-slider-item{
            .rooms-slider-text-overlay{
                .rooms-slider-img-overlay{
                    width: 209px;
                }
            }
        }
    }
}

@media (max-width: 325px){
    .rooms-slider-container{
        .rooms-slider-item{
            .rooms-slider-text-overlay{
                .rooms-slider-img-overlay{
                    width: 200px;
                }
            }
        }
    }
}