/* MAIN NAVIGATION STYLES */
.nav-backdrop {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000000;
    cursor: pointer;
    z-index: -80;
    @include opacity(0);
    @include transition(opacity 0.3s linear);

    &.show {
        top: 0;
        z-index: 80;
        @include opacity(0);
    }
}

.main-navigation-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    overflow: hidden;
    cursor: pointer;

    &.open {
        left: 0;
        width: 100%;
    }
}

.main-navigation {
    position: fixed !important;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 90 !important;
    max-height: 100vh;
    @include gradient-two-clr-linear(#2d2e2e, #232423);
    overflow-y: auto;
    width: 350px;
    @include translate(-100%, 0);
    @include transition(all 0.3s ease-out);

    .nav-list {
        font-size: 0.95rem;
        .nav-item {
            padding: 18px 25px;
            font-size: 0.95rem;
            border-top: 0.5px solid #323232;
            text-transform: uppercase;
            @include transition(all 0.2s ease-in-out);

            a {
                color: color(white-clr);
                display: block;
                text-decoration: none !important;

                &:hover,
                &:active {
                    color: #ffffff;
                }
            }

            &:last-of-type {
                border-bottom: 0.5px solid #323232;
            }
        }

        .dropdown-list {
            margin-top: 15px;

            li {
                font-size: 1.6rem;
                text-transform: initial;
                padding: 3px 0;
                border-top: 1px solid rgba(255, 255, 255, 0.1);

                a {
                    margin-left: 15px;
                }
            }
        }
    }

    &.show {
        @include translate(0, 0);
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.2);
    }

    &.light-theme {
        background-color: #ffffff;

        .nav-list {
            .nav-list-item {
                border-color: rgba(0, 0, 0, 0.1);
                @include opacity(0.8);

                a {
                    color: $text_dark;

                    &:hover,
                    &:active {
                        color: darken($text_dark, 10%);
                    }
                }
            }
        }

        .btn-close {
            color: $text_dark;
        }
    }
}

/* MEDIA QUERIES */
@media (min-width: 1024px) {

    /* SCROLLBAR STYLE */
    .main-navigation {
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(146, 192, 31, 0.3);
            background-color: transparent;
        }

        &::-webkit-scrollbar {
            width: 12px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(146, 192, 31, 0.7);
        }

        &.light-theme {
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }
}

@media (max-width: $media_sm) {
    .main-navigation {
        .nav-list {
            .nav-list-item {
                font-size: 1.7rem;
            }

            .dropdown-list {
                li {
                    font-size: 1.5rem;
                }
            }
        }
    }
}

@media (max-width: 375px) {
    .main-navigation {
        width: 85%;
    }
}