#main-footer {
    @include gradient-two-clr-diagonal--45deg(color(text-darker), color(text-dark));
    padding-top: 2vh;

    .footer-section {
        padding-top: 4vh;
        padding-bottom: 4vh;

        &:last-of-type {
            padding-bottom: 1vh;
        }

        .footer-logos {
            li {
                padding-right: 10px;
                padding-left: 10px;
            }
        }
    }

    .footer-navigation {

        .footer-nav {
            padding-top: 3vh;
            padding-bottom: 1vh;

            ul {
                font-size: 0.95rem;

                li {
                    border-right: 1px solid #646464;
                    padding-right: 10px;
                    font-size: 0.95rem !important;
                    text-transform: uppercase;

                    &:last-of-type {
                        border: none;
                        padding-right: 0;
                    }
                }
            }
        }
    }

    .footer-share {
        z-index: -1;
        border-top: 1px solid rgba(83, 83, 83, 1);
        border-bottom: 1px solid rgba(83, 83, 83, 1);
        padding-bottom: 0;

        .social-media {
            padding-top: 2vh;
            padding-bottom: 3vh;

            .at-share-btn {
                background-color: transparent !important;
//                border: 2px solid color(white-clir);

                &:hover {
                    color: color(btn-clr-large);
                    border-color: color(btn-clr-large);

                }

                .at-icon:hover {
                    fill: color(btn-clr-large) !important;
                }
            }
        }

        .btn-follow {
            color: #fff;
            border: 1px solid #6e6e6e;
            border-radius: 20px;
            position: relative;
            bottom: -15px;
            // left: 40%;
            background: #404040;
            /* Old browsers */
            background: -moz-linear-gradient(left, #404040 0%, #444444 50%, #474747 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(left, #404040 0%, #444444 50%, #474747 100%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, #404040 0%, #444444 50%, #474747 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#404040', endColorstr='#474747', GradientType=1);
            /* IE6-9 */
        }
    }

    .footer-contact {
        padding-top: 5vh;

        ul {
            li {
                border-right: 1px solid #646464;
                padding-right: 10px;
                font-size: 1rem;

                &:last-of-type {
                    border: none;
                    padding-right: 0;
                }
            }
        }
    }

    .footer-partners {
        padding: 40px 0 20px;
        text-align: center;
        background-color: color(white-clr);
        color: color(text-dark);
        padding-bottom: 1vh;
        padding-top: 2vh;
        .container{
            max-width: unset !important;
        }
        .footer-logos {
            text-align: center;
            

            .htz-hrvatska {
                text-align: center;
                height: 48px;
                width: auto;
            }

            .tz-split {
                text-align: center;
                max-height: 80px;
                width: auto;
            }

            .hotels {
                padding-top: 20px;
                padding-bottom: 20px;

            }
        }

        ul {
            li {
                display: inline-block;
                margin-left: -4px;
                @include transition(opacity 0.15s ease-in-out);

                &:hover {
                    @include opacity(0.6);
                }
            }
        }
    }

    .footer-copyright {
        border-top: 1px solid #e5e5e5;
        background: #fff;
        color: color(text-dark);
        padding-top: 1vh;

        .dimedia-logo {
            max-height: 21px;
            max-width: auto;
        }

        a {
            color: color(text-dark);
        }
    }

    a {
        color: color(white-clr);
        text-decoration: none;
    }



    .social-links {
        margin-top: 7vh;
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 400;
    }

    .sitemap {
        margin: 2rem auto;

        a {
            border-bottom: 1px dotted #fff;
            font-weight: 300;
        }
    }

    .weatherwidget-io{
       max-width: 225px;
       width: 225px;
       vertical-align: middle;
       display: inline-block !important;
    }
    .widget-label{
        color: color(white-clr);
        display: inline-block;
        vertical-align: middle;
    }
}



@media (max-width: $media_lg) {
    #main-footer {
        .footer-nav, .logo-footer {
            display: none;
        }

        .social-links {
            padding-top: 25vh;
        }
    }
}

@media (max-width: $media_sm) {
    #main-footer {
        .social-links {
            padding-top: 15vh;
        }
    }
}

// STARTUP CODE
/* FOOTER VARS */
$footer_bg: #36434d;
$footer_color_accent: #ffffff;
$footer_color_main: #b7b7b7;

/* FOOTER STYLES */
.accent {
    color: $footer_color_accent !important;
    font-weight: 700;
}

.ft-title {
    @extend .accent;
    font-family: $font_main;
    font-size: 1.6rem;
    text-transform: uppercase;
}

.footer {
    font-size: 1.4rem;
    color: $footer_color_main;
    padding: 50px 0;

    h4 {
        margin-bottom: 15px;
        line-height: 1;
        @extend .ft-title;
    }

    i {
        color: $footer_color_accent;
        margin-right: 10px;
    }

    address {
        margin-bottom: 0;

        i {
            font-size: 16px;

            &.fa-skype {
                font-size: 15px;
            }

            &.fa-envelope {
                font-size: 14px;
            }

            &.fa-mobile {
                font-size: 20px;
                margin-right: 5px;
                position: relative;
                right: 2px;
            }
        }
    }
}

.footer-info {
    background-color: $footer_bg;
    font-weight: 300;
}

.footer-links {
    background-color: darken($footer_bg, 4%);

    .ft-title {
        margin: 0;
        line-height: 35px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        a {
            &:hover {
                text-decoration: none;
                opacity: 0.75;
            }
        }
    }

    ul {
        li {
            line-height: 35px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            /*             background-color: darken($footer_bg, 4%);
            @include transition(all 0.2s ease-in-out); */
            a {
                display: block;

                &:hover {
                    text-decoration: none;
                    opacity: 0.75;
                }
            }

            /*             &:hover {
                background-color: darken($footer_bg, 8%);
                @include transition(opacity 0.7s);
            } */
        }
    }
}


.footer-bottom {
    background-color: darken($footer_bg, 12%);
    padding: 40px 0;
    font-size: 1.4rem;
    font-weight: 300;
    color: #898989;

    ul {
        margin: 0;
        display: flex;
        justify-content: space-between;

        li {
            span {
                color: #cd0000;
            }

            .dm-link {
                &:hover {
                    color: #cd0000;
                }
            }
        }
    }
}

/* NEWSLETTER */
.dmn-input-group {
    max-height: 42px;

    .dmn-form-control {
        padding: 0.95rem 1.5rem;
        background-color: rgba(255, 255, 255, 0.02);
        border-radius: 0;
        border-color: $border_light;
        color: #d6d6d6;
        font-size: 1.4rem;
        max-width: 220px;
        overflow: hidden;

        &:focus {
            box-shadow: 0 0 0 2px fade($secondary, 20%);
        }
    }

    button {
        width: 55px;
        border: 1px solid $secondary;
        background-color: $secondary;
        border-radius: 0;
        font-size: 1.8rem;
        padding: 0.65rem 0.75rem;
        justify-content: center;
        @include transition(background 0.15s ease-in-out);

        i {
            margin: 0;
        }

        &:hover {
            background-color: lighten($secondary, 2%);
        }
    }
}

// /* SOCIAL SHARE POPUP/LINKS */
.social-container {
    position: absolute;
    bottom: 0;
}

.social-link {
    border-right: 1px solid $border_light;
    text-transform: uppercase;
    font-weight: 700;

    &:last-of-type {
        border: 0;
    }

    &:not(.icon-only) {
        padding-right: 20px;
        color: #ffffff;
        line-height: 32px;
        display: inline-block;
    }

    &.icon-only {
        width: 50px;
        line-height: 32px;
        text-align: center;
        display: inline-block;
        margin-left: -4px;

        i {
            font-size: 1.6rem;
            margin: 0;
            @include transition(color 0.15s ease-in-out);

            &.fa-facebook,
            &.fa-google-plus {
                font-size: 1.5rem;
            }
        }
    }

    &:hover {
        text-decoration: none;
        color: $text-dark;

        i {
            color: $text-dark;
        }
    }
}

/* SOCIAL SHARE POPUP */
.social-popup {
    display: none;
    position: relative;
    top: -10%;
    //background-color: darken($footer_bg, 4%);
    //max-width: 115px;
    //padding: 5px;

    &:after {
        content: "";
        width: 0;
        height: 0;
        border: 7px solid transparent;
        border-top-color: darken($clr-theme-light, 20%);
        position: absolute;
        bottom: -14px;
        left: 7px;
        margin-left: -7px;
        @include opacity(0.3);
        @include rotate(-40deg);
        @include transition(all 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55));
    }

    a {
        width: 30px;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        margin-left: -4px;
        background-color: darken($clr-theme-light, 20%);
        text-align: center;
        -webkit-font-smoothing: antialiased;
        @include transition(all 0.15s ease-in-out);
        @include translate3d(0, 0, 0);

        &:first-of-type {
            margin-left: 0;
        }

        i {
            margin: 0;
            -webkit-font-smoothing: antialiased;
            @include translate3d(0, 0, 0);

            &.fa-envelope {
                font-size: 1.3rem;
            }
        }

        &:hover {
            @include opacity(0.65);
        }
    }

    &.active {
        display: block;

        a {
            -webkit-animation-name: socialPopup;
            animation-name: socialPopup;
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
            //@include loop-list(0.25s, 4);
        }
    }

    &.on {
        &:after {
            left: 25%;
            @include opacity(1);
            @include rotate(0);
        }
    }
}

/* TRANSITION/DELAY LOOP */
/* LESS
@mixin loop-list($duration, $n, $i: 1) when ($i <= $n) {
    &:nth-of-type(${i}) {
        -webkit-animation-duration: ($duration*$i); // code for each iteration
        animation-duration: ($duration*$i); // code for each iteration
    }
    .loop-list($duration, $n, ($i + 1)); // next iteration
}*/
/* SASS */
@for $i from 1 through 4 {
    .social-popup.active a:nth-of-type(#{$i}) {
        -webkit-animation-duration: (0.25s*$i); // code for each iteration
        animation-duration: (0.25s*$i); // code for each iteration
    }
}

/* KEYFRAMES */
@-webkit-keyframes socialPopup {
    0% {
        opacity: 0;
        transform: rotate(-30deg) translateY(-50px) rotateX(-90deg);
    }

    50% {
        opacity: 1;
        transform: rotate(10deg) translateY(5px) rotateX(2deg);
    }

    100% {
        transform: rotate(0) translateY(0) rotateX(0);
    }
}

@keyframes socialPopup {
    0% {
        opacity: 0;
        transform: rotate(-30deg) translateY(-50px) rotateX(-90deg);
    }

    50% {
        opacity: 1;
        transform: rotate(10deg) translateY(5px) rotateX(2deg);
    }

    100% {
        transform: rotate(0) translateY(0) rotateX(0);
    }
}

/* MEDIA QUERIES */
@media (max-width: $media_xl) {
    .footer-partners {
        p {
            img {
                margin: 10px auto 30px;
            }
        }
    }
}

@media (max-width: $media_lg) {
    .footer-links {
        display: none;
    }

    .footer-partners {
        p {
            img {
                display: block;
            }
        }
    }

    .footer-bottom {
        ul {
            flex-direction: column;
            text-align: center;
        }
    }
}

@media (max-width: $media_md) {
    .dmn-input-group {
        max-height: 36px;

        .dmn-form-control {
            padding: 0.95rem 1rem;
            font-size: 1.3rem;
        }

        button {
            width: 40px;
            padding: 0.35rem 0.75rem;
        }
    }

    .social-link {
        &:first-of-type {
            padding-right: 15px;
        }

        &.icon-only {
            width: 40px;
            line-height: 28px;
        }
    }
}

@media (max-width: $media_md) {
    .footer-logos {
        height: auto !important;

        li {
            padding-top: 15px;
        }
    }

    .footer-copyright {
        .row {
            div {
                text-align: center !important;
                padding-top: 10px;
            }
        }
    }
}

@media (max-width: $media_sm) {
    #main-footer{
        .footer-share{
            .social-media {
                .at-share-btn {
            
                    .at-icon-wrapper{
                        line-height: 25px !important;
                        height: 25px !important;
                        width: 25px !important;
                        svg {
                            width: 25px !important;
                            height: 25px !important;
                        }
                    }
                }
            }
        }
    }
    
    .social-container {
        position: relative;
        margin-top: 45px;
    }
}