.text-page {
    font-family: font(montserrat);
    padding-top: 5vh;
    ul {
        font-size: 1rem;
    }

    h1 {
        font-size: 1.85rem;
        text-transform: uppercase;
        font-weight: 300;
    }

    ul li::before {
        content: "\2022";
        /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: #ad824b;
        /* Change the color */
        font-weight: bold;
        /* If you want it to be bold */
        display: inline-block;
        /* Needed to add space between the bullet and the text */
        width: 1rem;
        /* Also needed for space (tweak if needed) */

        /* Also needed for space (tweak if needed) */
    }

    hr.text-divider {
        border-top: 3px solid #daaf5c;
        width: 10%;
    }

}