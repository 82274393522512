.back-btn {
    display: inline-block;
    margin-bottom: 1rem;
    font-size: 1rem;
    color: color(text-dark) !important;

    i {
        font-size: 12px;
        color: color(text-dark);
        padding-right: 5px;
    }

    &:hover {
        text-decoration: none;
    }
}

.btn-circular {
    border-radius: 40px !important;
}

.btn-large {
    background-color: color(btn-clr-large);
    border: color(btn-clr-large) 2px solid;
    z-index: 2 !important;
    position: relative;
    font-size: 14px;
    color: color(white-clr) !important;

    &:hover {
        background-color: color(color-them-light);
        color: color(btn-clr-large) !important;
    }
}

.btn-shadow {
    -webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.3);
}

.btn-large-secondary {
    @extend .btn-large;
    background-color: color(white-clr);
    border: 2px solid #ccc;
    color: color(text-dark) !important;

    &:hover {
        background-color: color(btn-clr-large);
        border: color(btn-clr-large) 2px solid;
        color: #fff !important;
    }
}

.btn-small {
    margin-left: -1.25rem !important;
    z-index: 1 !important;
    background-color: color(color-them-light);
    color: color(btn-clr-large);
    border-color: #cccac7;
    font-size: 10px;

    &:hover {
        background-color: color(btn-clr-large);
        color: color(white-clr) !important;
        border-color: color(btn-clr-large) !important;
    }
}


.btn-gradient {
    @include gradient-two-clr-linear($clr-theme-light, $clr-theme-light-b);
    color: #fff !important;
    font-size: 1.2rem;
    border: none;
    line-height: 1.3rem;
    padding: 0.25rem 1rem;
    margin-top: 0.7rem;
    border-radius: 0.3rem;
    @include transition(all 0.3s ease-in);

    &:hover {
        @include gradient-two-clr-linear($clr-theme-light-b, $clr-theme-light);
    }
}


.btn-notebook-card {
    @include gradient-two-clr-linear($clr-theme-light, $clr-theme-light-b);
    padding: 1px;
    display: inline-block;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    border-radius: 0.3rem;
    font-size: 1.1rem;
    font-family: $font_Unica_One;
    font-weight: 400;

    .inner-btn-text {
        @include inner-btn-value;
    }
}

@media (max-width: $media_lg) {
    .btn-notebook-card {
        margin-left: 3.5rem;
        margin-right: 3.5rem;
    }
}

@media (max-width: $media_md) {
    .btn-notebook-card {
        margin-left: 5rem;
        margin-right: 5rem;
    }
}

@media (max-width: $media_sm) {
    .btn-notebook-card {
        display: block;
        margin-left: 0;
        margin-right: 0;
    }
}

.btn-detail-card {
    @extend .btn-notebook-card;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
}




#share-icon {
    position: absolute;
    top: 0;
    right: 10px;
    color: #daaf5c;
}

.social-icon-container {
    position: absolute;
    top: 25px;
    right: 10px;
}

.social-icon-popup {
    display: none;
    position: relative;
    top: -10%;
    background-color: color(subtitle-color);

    //max-width: 115px;
    //padding: 5px;

    // &:after {
    //     content: "";
    //     width: 0;
    //     height: 0;
    //     border: 7px solid transparent;
    //     border-top-color: darken($clr-theme-light, 20%);
    //     position: absolute;
    //     bottom: -14px;
    //     left: 7px;
    //     margin-left: -7px;
    //     @include opacity(0.3);
    //     @include rotate(-40deg);
    //     @include transition(all 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55));
    // }

    a {
        color: #fff;
        width: 30px;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        margin-left: -4px;
        background-color: color(subtitle-color);
        text-align: center;
        -webkit-font-smoothing: antialiased;
        @include transition(all 0.15s ease-in-out);
        @include translate3d(0, 0, 0);

        &:first-of-type {
            margin-left: 0;
        }

        i {
            margin: 0;
            -webkit-font-smoothing: antialiased;
            @include translate3d(0, 0, 0);

            &.fa-envelope {
                font-size: 1.3rem;
            }
        }

        &:hover {
            @include opacity(0.65);
        }
    }

    &.active {
        display: block;

        a {
            -webkit-animation-name: socialPopup;
            animation-name: socialPopup;
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
            //@include loop-list(0.25s, 4);
        }
    }

    &.on {
        &:after {
            left: 50%;
            @include opacity(1);
            @include rotate(0);
        }
    }
}